import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" api="extension-version" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static String extensionVersion() {
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`String mediaExtensionVersion = Media.extensionVersion();
`}</code></pre>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val mediaExtensionVersion = Media.extensionVersion()
`}</code></pre>
    <Variant platform="ios" api="extension-version" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static var extensionVersion: String
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let mediaExtensionVersion = Media.extensionVersion()
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (nonnull NSString*) extensionVersion;
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSString *mediaExtensionVersion = [AEPMobileEdgeMedia extensionVersion];
`}</code></pre>
    <Variant platform="android" api="create-tracker" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static MediaTracker createTracker()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MediaTracker mediaTracker = Media.createTracker();  // Use the instance for tracking media.
`}</code></pre>
    <h4 {...{
      "id": "kotlin-1"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val tracker = Media.createTracker()
`}</code></pre>
    <Variant platform="ios" api="create-tracker" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createTracker()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let tracker = Media.createTracker()  // Use the instance for tracking media.
`}</code></pre>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (void) createTracker
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`id<AEPEdgeMediaTracker> tracker;
tracker = [AEPMobileEdgeMedia createTracker];  // Use the instance for tracking media.
`}</code></pre>
    <Variant platform="android" api="create-tracker-with-config" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static MediaTracker createTracker(Map<String, Object> config)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> config = new HashMap<String, Object>();
config.put(MediaConstants.TrackerConfig.CHANNEL, "custom-channel");  // Overwrites channel configured in the Data Collection UI.
config.put(MediaConstants.TrackerConfig.AD_PING_INTERVAL, 1);   // Overwrites ad content ping interval to 1 second.
config.put(MediaConstants.TrackerConfig.MAIN_PING_INTERVAL, 30);   // Overwrites main content ping interval to 30 seconds.

MediaTracker mediaTracker = Media.createTracker(config);  // Use the instance for tracking media.
`}</code></pre>
    <h4 {...{
      "id": "kotlin-2"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val config = mapOf(
                MediaConstants.TrackerConfig.CHANNEL to "custom-channel",
                MediaConstants.TrackerConfig.AD_PING_INTERVAL to 1,
                MediaConstants.TrackerConfig.MAIN_PING_INTERVAL to 30,
            )
val tracker = Media.createTracker(config) // Use the instance for tracking media.
`}</code></pre>
    <Variant platform="ios" api="create-tracker-with-config" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-2"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createTrackerWith(config: [String: Any]?)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var config: [String: Any] = [:]
config[MediaConstants.TrackerConfig.CHANNEL] = "custom-channel" // Overrides channel configured in the Data Collection UI.
​config[MediaConstants.TrackerConfig.AD_PING_INTERVAL] = 1 // Overwrites ad content ping interval to 1 second.
config[MediaConstants.TrackerConfig.MAIN_PING_INTERVAL] = 30 // Overwrites main content ping interval to 30 seconds.

let tracker = Media.createTrackerWith(config: config)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-2"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+(id<AEPEdgeMediaTracker> _Nonnull) createTrackerWithConfig:(NSDictionary<NSString *,id> * _Nullable)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`id<AEPEdgeMediaTracker> tracker;
NSMutableDictionary* config = [NSMutableDictionary dictionary];

config[AEPEdgeMediaTrackerConfig.CHANNEL] = @"custom-channel"; // Overrides channel configured in the Data Collection UI.
config[AEPEdgeMediaTrackerConfig.AD_PING_INTERVAL] = 1; // Overwrites ad content ping interval to 1 second.
config[AEPEdgeMediaTrackerConfig.MAIN_PING_INTERVAL] = 30; // Overwrites main content ping interval to 30 seconds.

tracker = [AEPMobileEdgeMedia createTrackerWithConfig:config];
`}</code></pre>
    <Variant platform="android" api="create-media-object" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static HashMap<String, Object> createMediaObject(String name,
                                                        String id,
                                                        int length,
                                                        String streamType,
                                                        MediaType mediaType);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> mediaInfo = Media.createMediaObject("videoName",
                                                            "videoId",
                                                            60,
                                                            MediaConstants.StreamType.VOD,
                                                            Media.MediaType.Video);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-3"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`var mediaInfo = Media.createMediaObject("videoName",
                                        "videoId",
                                        60,
                                        MediaConstants.StreamType.VOD,
                                        Media.MediaType.Video)
`}</code></pre>
    <Variant platform="ios" api="create-media-object" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-3"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createMediaObjectWith(name: String,
                                    id: String,
                                length: Int,
                            streamType: String,
                             mediaType: MediaType) -> [String: Any]?
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let mediaObject = Media.createMediaObjectWith(name: "videoName",
                                                id: "videoId",
                                            length: 60,
                                        streamType: MediaConstants.StreamType.VOD,
                                         mediaType: MediaType.Video)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-3"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (NSDictionary<NSString *, id> * _Nullable) createMediaObjectWith:(NSString * _Nonnull) id:(NSString * _Nonnull) length:(NSInteger) streamType:(NSString * _Nonnull) mediaType:(enum AEPEdgeMediaType)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *mediaObject = [AEPMobileEdgeMedia createMediaObjectWith:@"videoName"
                                                                id:@"videoId"
                                                            length:60
                                                        streamType:AEPEdgeMediaStreamType.VOD
                                                         mediaType:AEPEdgeMediaTypeVideo];
`}</code></pre>
    <Variant platform="android" api="create-ad-break-object" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-4"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static HashMap<String, Object> createAdBreakObject(String name, int position, int startTime);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> adBreakInfo = Media.createAdBreakObject("adbreakName", 1, 0);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-4"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val adBreakInfo = Media.createAdBreakObject("adbreakName", 1, 0)
`}</code></pre>
    <Variant platform="ios" api="create-ad-break-object" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-4"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createAdBreakObjectWith(name: String,
                                position: Int,
                               startTime: Int) -> [String: Any]?
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let adBreakInfo = Media.createAdBreakObjectWith(name: "adbreakName",
                                              position: 1,
                                             startTime: 0)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-4"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (NSDictionary  <NSString *, id> * _Nullable) createAdBreakObjectWith:(NSString * _Nonnull)position:(NSInteger) startTime:(NSInteger)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *adBreakInfo = [AEPMobileEdgeMedia createAdBreakObjectWith:@"adbreakName"
                                                                 position:1
                                                                startTime:0];
`}</code></pre>
    <Variant platform="android" api="create-ad-object" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-5"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static HashMap<String, Object> createAdObject(String name, String id, int position, int length);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> adInfo = Media.createAdObject("adName", "adId", 1, 15);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-5"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val adInfo = Media.createAdObject("adName", "adId", 1, 15)
`}</code></pre>
    <Variant platform="ios" api="create-ad-object" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-5"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createAdObjectWith(name: String,
                                 id: String,
                           position: Int,
                             length: Int) -> [String: Any]?
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let adInfo = Media.createObjectWith(name: "adName",
                                        id: "adId",
                                  position: 0,
                                    length: 30)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-5"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (NSDictionary  <NSString *, id> * _Nullable) createAdObjectWith:(NSString * _Nonnull)
                                                               id:(NSString * _Nonnull)
                                                         position:(NSInteger)
                                                           length:(NSInteger)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *adInfo = [AEPMobileEdgeMedia createAdObjectWith:@"adName"
                                                             id:@"adId"
                                                       position:0
                                                         length:30];
`}</code></pre>
    <Variant platform="android" api="create-chapter-object" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-6"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static HashMap<String, Object> createChapterObject(String name,
                                                          int position,
                                                          int length,
                                                          int startTime);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> chapterInfo = Media.createChapterObject("chapterName", 1, 60, 0);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-6"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val chapterInfo = Media.createChapterObject("chapterName", 1, 60, 0)
`}</code></pre>
    <Variant platform="ios" api="create-chapter-object" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-6"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createChapterObjectWith(name: String,
                                position: Int,
                                  length: Int,
                               startTime: Int) -> [String: Any]?
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let chapterInfo = Media.createChapterObjectWith(name: "chapterName",
                                              position: 1,
                                                length: 60,
                                             startTime: 0)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-6"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (NSDictionary  <NSString *, id> * _Nullable) createChapterObjectWith:(NSString * _Nonnull)
                                                              position:(NSInteger)
                                                                length:(NSInteger)
                                                             startTime:(NSInteger)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivc"
      }}>{`NSDictionary *chapterInfo = [AEPMobileEdgeMedia createChapterObjectWith:@"chapterName"
                                                                 position:1
                                                                   length:60
                                                                startTime:0];
`}</code></pre>
    <Variant platform="android" api="create-qoe-object" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-7"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static HashMap<String, Object> createQoEObject(int bitrate,
                                                      int startupTime,
                                                      int fps,
                                                      int droppedFrames);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> qoeInfo = Media.createQoEObject(10000000, 2, 23, 10);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-7"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val qoeInfo = Media.createQoEObject(10000000, 2, 23, 10)
`}</code></pre>
    <Variant platform="ios" api="create-qoe-object" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-7"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createQoEObjectWith(bitrate: Int,
                            startupTime: Int,
                                    fps: Int,
                          droppedFrames: Int) -> [String: Any]?
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let qoeInfo = Media.createQoEObjectWith(bitrate: 500000,
                                      startupTime: 2,
                                              fps: 24,
                                    droppedFrames: 10)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-7"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (NSDictionary  <NSString *, id> * _Nullable) createQoEObjectWith:(NSInteger)
                                                         startTime:(NSInteger)
                                                               fps:(NSInteger)
                                                     droppedFrames:(NSInteger)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *qoeInfo = [AEPMobileEdgeMedia createQoEObjectWith:500000
                                                        startTime:2
                                                              fps:24
                                                    droppedFrames:10];
`}</code></pre>
    <Variant platform="android" api="create-state-object" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-8"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static HashMap<String, Object> createStateObject(String stateName);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> fullScreenState = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-8"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val fullScreenState = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN)
`}</code></pre>
    <Variant platform="ios" api="create-state-object" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-8"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func createStateObjectWith(stateName: String) -> [String: Any]
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let fullScreenState = Media.createStateObjectWith(stateName: MediaConstants.PlayerState.FULLSCREEN)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-8"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (NSDictionary  <NSString *, id> * _Nullable) createStateObjectWith:(NSString * _Nonnull)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary* fullScreenState = [AEPMobileEdgeMedia createStateObjectWith:AEPEdgeMediaPlayerState.FULLSCREEN]
`}</code></pre>
    <Variant platform="android" api="track-session-start" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-9"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void trackSessionStart(Map<String, Object> mediaInfo, Map<String, String> contextData);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> mediaInfo = Media.createMediaObject("mediaName", "mediaId", 60, MediaConstants.StreamType.VOD, Media.MediaType.Video);

HashMap<String, String> mediaMetadata = new HashMap<String, String>();
// Standard metadata keys provided by adobe.
mediaMetadata.put(MediaConstants.VideoMetadataKeys.EPISODE, "Sample Episode");
mediaMetadata.put(MediaConstants.VideoMetadataKeys.SHOW, "Sample Show");

// Custom metadata keys
mediaMetadata.put("isUserLoggedIn", "false");
mediaMetadata.put("tvStation", "Sample TV Station");

tracker.trackSessionStart(mediaInfo, mediaMetadata);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-9"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val mediaInfo = Media.createMediaObject(
                        "mediaName",
                        "mediaId",
                        60,
                        MediaConstants.StreamType.VOD,
                        Media.MediaType.Video
                    )

val mediaMetadata = mapOf<String, String>(
    // Standard metadata keys provided by Adobe
    MediaConstants.VideoMetadataKeys.EPISODE to "Sample Episode",
    MediaConstants.VideoMetadataKeys.SHOW to "Sample Show",
    // Custom metadata keys
    "isUserLoggedIn" to "false",
    "tvStation" to "Sample TV Station"
)

tracker.trackSessionStart(mediaInfo, mediaMetadata)
`}</code></pre>
    <Variant platform="ios" api="track-session-start" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-9"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public func trackSessionStart(info: [String: Any], metadata: [String: String]? = nil)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let mediaInfo = Media.createMediaObjectWith(name: "videoName", id: "videoId", length: 60, streamType: MediaConstants.StreamType.VOD, mediaType: MediaType.Video)

var mediaMetadata: [String: String] = [
  // Standard metadata keys provided by Adobe
  MediaConstants.VideoMetadataKeys.SHOW : "Sample Show",
  MediaConstants.VideoMetadataKeys.SEASON : "Sample Season",
  // Custom metadata keys
  "isUserLoggedIn" : "false",
  "isUserLoggedIn" : "Sample TV station"
]

tracker.trackSessionStart(info: mediaInfo, metadata: mediaMetadata)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-9"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (void) trackSessionStart:(NSDictionary<NSString *,id> * _Nonnull) metadata:(NSDictionary<NSString *,NSString *> * _Nullable)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *mediaInfo = [AEPMobileEdgeMedia createMediaObjectWith:@"videoName" id:@"videoId" length:60 streamType:AEPEdgeMediaStreamType.VOD mediaType:AEPEdgeMediaTypeVideo];

NSMutableDictionary *mediaMetadata = [[NSMutableDictionary alloc] init];
// Standard metadata keys provided by Adobe
[mediaMetadata setObject:@"Sample Show" forKey:AEPEdgeMediaVideoMetadataKeys.SHOW];
[mediaMetadata setObject:@"Sample Season" forKey:AEPEdgeMediaVideoMetadataKeys.SEASON];

// Custom metadata keys
[mediaMetadata setObject:@"false" forKey:@"isUserLoggedIn"];
[mediaMetadata setObject:@"Sample TV station" forKey:@"tvStation"];

[tracker trackSessionStart:mediaInfo metadata:mediaMetadata];
`}</code></pre>
    <Variant platform="android" api="track-play" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-10"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void trackPlay();
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackPlay();
`}</code></pre>
    <h4 {...{
      "id": "kotlin-10"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackPlay()
`}</code></pre>
    <Variant platform="ios" api="track-play" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-10"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func trackPlay()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`tracker.trackPlay()
`}</code></pre>
    <h4 {...{
      "id": "objective-c-10"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) trackPlay;
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[tracker trackPlay];
`}</code></pre>
    <Variant platform="android" api="track-pause" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-11"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void trackPause();
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackPause();
`}</code></pre>
    <h4 {...{
      "id": "kotlin-11"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackPause()
`}</code></pre>
    <Variant platform="ios" api="track-pause" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-11"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func trackPause()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`tracker.trackPause()
`}</code></pre>
    <h4 {...{
      "id": "objective-c-11"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) trackPause
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[tracker trackPause];
`}</code></pre>
    <Variant platform="android" api="track-complete" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-12"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void trackComplete();
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackComplete();
`}</code></pre>
    <h4 {...{
      "id": "kotlin-12"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackComplete()
`}</code></pre>
    <Variant platform="ios" api="track-complete" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-12"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func trackComplete()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`tracker.trackComplete()
`}</code></pre>
    <h4 {...{
      "id": "objective-c-12"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) trackComplete
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[tracker trackComplete];
`}</code></pre>
    <Variant platform="android" api="track-session-end" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-13"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void trackSessionEnd();
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackSessionEnd();
`}</code></pre>
    <h4 {...{
      "id": "kotlin-13"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`tracker.trackSessionEnd()
`}</code></pre>
    <Variant platform="ios" api="track-session-end" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-13"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func trackSessionEnd()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`tracker.trackSessionEnd()
`}</code></pre>
    <h4 {...{
      "id": "objective-c-13"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) trackSessionEnd
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[tracker trackSessionEnd];
`}</code></pre>
    <Variant platform="android" api="track-error" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-14"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void trackError(String errorId);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackError("errorId");
`}</code></pre>
    <h4 {...{
      "id": "kotlin-14"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackError("errorId")
`}</code></pre>
    <Variant platform="ios" api="track-error" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-14"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func trackError(errorId: String)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`tracker.trackError(errorId: "errorId")
`}</code></pre>
    <h4 {...{
      "id": "objective-c-14"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) trackError:(NSString * _Nonnull)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[tracker trackError:@"errorId"];
`}</code></pre>
    <Variant platform="android" api="track-event" repeat="30" mdxType="Variant" />
    <h4 {...{
      "id": "java-15"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`  public void trackEvent(Media.Event event,
                         Map<String, Object> info,
                         Map<String, String> data);
`}</code></pre>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <p><strong parentName="p">{`Tracking player states`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// StateStart
  HashMap<String, Object> fullScreenState = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN);
  tracker.trackEvent(Media.Event.StateStart, fullScreenState, null);

// StateEnd
  HashMap<String, Object> fullScreenState = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN);
  tracker.trackEvent(Media.Event.StateEnd, fullScreenState, null);
`}</code></pre>
    <p><strong parentName="p">{`Tracking ad breaks`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// AdBreakStart
  HashMap<String, Object> adBreakInfo = Media.createAdBreakObject("adbreakName", 1, 0);
  tracker.trackEvent(Media.Event.AdBreakStart, adBreakInfo, null);

// AdBreakComplete
  tracker.trackEvent(Media.Event.AdBreakComplete, null, null);
`}</code></pre>
    <p><strong parentName="p">{`Tracking ads`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// AdStart
  HashMap<String, Object> adInfo = Media.createAdObject("adName", "adId", 1, 15);

  HashMap<String, String> adMetadata = new HashMap<String, String>();
  // Standard metadata keys provided by Adobe
  adMetadata.put(MediaConstants.AdMetadataKeys.ADVERTISER, "Sample Advertiser");
  adMetadata.put(MediaConstants.AdMetadataKeys.CAMPAIGN_ID, "Sample Campaign");
  // Custom metadata keys
  adMetadata.put("affiliate", "Sample Affiliate");

  tracker.trackEvent(Media.Event.AdStart, adInfo, adMetadata);

// AdComplete
  tracker.trackEvent(Media.Event.AdComplete, null, null);

// AdSkip
  tracker.trackEvent(Media.Event.AdSkip, null, null);
`}</code></pre>
    <p><strong parentName="p">{`Tracking chapters`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// ChapterStart
  HashMap<String, Object> chapterInfo = Media.createChapterObject("chapterName", 1, 60, 0);

  HashMap<String, String> chapterMetadata = new HashMap<String, String>();
  chapterMetadata.put("segmentType", "Sample Segment type");

  tracker.trackEvent(Media.Event.ChapterStart, chapterInfo, chapterMetadata);

// ChapterComplete
  tracker.trackEvent(Media.Event.ChapterComplete, null, null);

// ChapterSkip
  tracker.trackEvent(Media.Event.ChapterSkip, null, null);
`}</code></pre>
    <p><strong parentName="p">{`Tracking playback events`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// BufferStart
  tracker.trackEvent(Media.Event.BufferStart, null, null);

// BufferComplete
  tracker.trackEvent(Media.Event.BufferComplete, null, null);

// SeekStart
  tracker.trackEvent(Media.Event.SeekStart, null, null);

// SeekComplete
  tracker.trackEvent(Media.Event.SeekComplete, null, null);
`}</code></pre>
    <p><strong parentName="p">{`Tracking bitrate changes`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// If the new bitrate value is available provide it to the tracker.
  HashMap<String, Object> qoeInfo = Media.createQoEObject(2000000, 2, 25, 10);
  tracker.updateQoEObject(qoeInfo);

// Bitrate change
  tracker.trackEvent(Media.Event.BitrateChange, null, null);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-15"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <p><strong parentName="p">{`Tracking player states`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// StateStart
    val stateInfo = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN)
    tracker.trackEvent(Media.Event.StateStart, stateInfo, null)

// StateEnd
    val stateInfo = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN)
    tracker.trackEvent(Media.Event.StateEnd, stateInfo, null)
`}</code></pre>
    <p><strong parentName="p">{`Tracking ad breaks`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// AdBreakStart
    val adBreakInfo = Media.createAdBreakObject("adbreakName", 1, 0)
    tracker.trackEvent(Media.Event.AdBreakStart, adBreakInfo, null)

// AdBreakComplete
    tracker.trackEvent(Media.Event.AdBreakComplete, null, null)
`}</code></pre>
    <p><strong parentName="p">{`Tracking ads`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`//AdStart
    val adInfo = Media.createAdObject("adName", "adId", 1, 15)

    val adMetadata = mapOf<String, String>(
    // Standard metadata keys provided by Adobe
    MediaConstants.AdMetadataKeys.ADVERTISER to "Sample Advertiser",
    MediaConstants.AdMetadataKeys.CAMPAIGN_ID to "Sample Campaign",
    // Custom metadata keys
    "affiliate" to "Sample Affiliate",
    "tvStation" to "Sample TV Station"
    )

    tracker.trackEvent(Media.Event.AdStart, adInfo, adMetadata)

// AdComplete
    tracker.trackEvent(Media.Event.AdComplete, null, null)

// AdSkip
    tracker.trackEvent(Media.Event.AdSkip, null, null)
`}</code></pre>
    <p><strong parentName="p">{`Tracking chapters`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// ChapterStart
  val chapterInfo = Media.createChapterObject("chapterName", 1L, 60, 0)

  val chapterMetadata = mapOf<String, String>(
    "segmentType" to "Sample Segment type"
  )

  tracker.trackEvent(Media.Event.ChapterStart, chapterInfo, chapterMetadata)

// ChapterComplete
  tracker.trackEvent(Media.Event.ChapterComplete, null, null)

// ChapterSkip
  tracker.trackEvent(Media.Event.ChapterSkip, null, null)
`}</code></pre>
    <p><strong parentName="p">{`Tracking playback events`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// BufferStart
   tracker.trackEvent(Media.Event.BufferStart, null, null)

// BufferComplete
   tracker.trackEvent(Media.Event.BufferComplete, null, null)

// SeekStart
   tracker.trackEvent(Media.Event.SeekStart, null, null)

// SeekComplete
   tracker.trackEvent(Media.Event.SeekComplete, null, null)
`}</code></pre>
    <p><strong parentName="p">{`Tracking bitrate changes`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// If the new bitrate value is available provide it to the tracker.
  val qoeInfo = Media.createQoEObject(2000000, 2, 25, 10)
  tracker.updateQoEObject(qoeInfo)

// Bitrate change
  tracker.trackEvent(Media.Event.BitrateChange, null, null)
`}</code></pre>
    <Variant platform="ios" api="track-event" repeat="32" mdxType="Variant" />
    <h4 {...{
      "id": "swift-15"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func trackEvent(event: MediaEvent, info: [String: Any]?, metadata: [String: String]?)
`}</code></pre>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <p><strong parentName="p">{`Tracking player states`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// StateStart
  let fullScreenState = Media.createStateObjectWith(stateName: MediaConstants.PlayerState.FULLSCREEN)
  tracker.trackEvent(event: MediaEvent.StateStart, info: fullScreenState, metadata: nil)

// StateEnd
  let fullScreenState = Media.createStateObjectWith(stateName: MediaConstants.PlayerState.FULLSCREEN)
  tracker.trackEvent(event: MediaEvent.StateEnd, info: fullScreenState, metadata: nil)
`}</code></pre>
    <p><strong parentName="p">{`Tracking ad breaks`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// AdBreakStart
  let adBreakInfo = Media.createAdBreakObjectWith(name: "adbreakName", position: 1, startTime: 0)
  tracker.trackEvent(event: MediaEvent.AdBreakStart, info: adBreakInfo, metadata: nil)

// AdBreakComplete
  tracker.trackEvent(event: MediaEvent.AdBreakComplete, info: nil, metadata: nil)
`}</code></pre>
    <p><strong parentName="p">{`Tracking ads`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// AdStart
  let adInfo = Media.createObjectWith(name: "adName", id: "adId", position: 0, length: 30)

// Standard metadata keys provided by Adobe
  var adMetadata: [String: String] = [:]
  adMetadata[MediaConstants.AdMetadataKeys.ADVERTISER] = "Sample Advertiser"
  adMetadata[MediaConstants.AdMetadataKeys.CAMPAIGN_ID] = "Sample Campaign"

// Custom metadata keys
  adMetadata["affiliate"] = "Sample Affiliate"

  tracker.trackEvent(event: MediaEvent.AdStart, info: adInfo, metadata: adMetadata)

// AdComplete
  tracker.trackEvent(event: MediaEvent.AdComplete, info: nil, metadata: nil)

// AdSkip
   tracker.trackEvent(event: MediaEvent.AdSkip, info: nil, metadata: nil)
`}</code></pre>
    <p><strong parentName="p">{`Tracking chapters`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// ChapterStart
  let chapterInfo = Media.createChapterObjectWith(name: "chapterName", position: 1, length: 60, startTime: 0)
  let chapterMetadata = ["segmentType": "Sample Segment type"]

  tracker.trackEvent(event: MediaEvent.ChapterStart, info: chapterInfo, metadata: chapterMetadata)

// ChapterComplete
  tracker.trackEvent(event: MediaEvent.ChapterComplete, info: nil, metadata: nil)

// ChapterSkip
  tracker.trackEvent(event: MediaEvent.ChapterSkip, info: nil, metadata: nil)
`}</code></pre>
    <p><strong parentName="p">{`Tracking playback events`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// BufferStart
   tracker.trackEvent(event: MediaEvent.BufferStart, info: nil, metadata: nil)

// BufferComplete
   tracker.trackEvent(event: MediaEvent.BufferComplete, info: nil, metadata: nil)

// SeekStart
   tracker.trackEvent(event: MediaEvent.SeekStart, info: nil, metadata: nil)

// SeekComplete
   tracker.trackEvent(event: MediaEvent.SeekComplete, info: nil, metadata: nil)
`}</code></pre>
    <p><strong parentName="p">{`Tracking bitrate change`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// If the new bitrate value is available provide it to the tracker.
  let qoeInfo = Media.createQoEObjectWith(bitrate: 500000, startupTime: 2, fps: 24, droppedFrames: 10)
  tracker.updateQoEObject(qoeInfo)

// Bitrate change
  tracker.trackEvent(event: MediaEvent.BitrateChange, info: nil, metadata: nil)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-15"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) trackEvent:(enum AEPEdgeMediaEvent) info:(NSDictionary<NSString *,id> * _Nullable) metadata:(NSDictionary<NSString *,NSString *> * _Nullable)
`}</code></pre>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <p><strong parentName="p">{`Tracking player states`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// StateStart
  NSDictionary* fullScreenState = [AEPMobileEdgeMedia createStateObjectWith:AEPEdgeMediaPlayerState.FULLSCREEN];
  [tracker trackEvent:AEPEdgeMediaEventStateStart info:fullScreenState metadata:nil];

// StateEnd
  NSDictionary* fullScreenState = [AEPMobileEdgeMedia createStateObjectWith:AEPEdgeMediaPlayerState.FULLSCREEN];
  [tracker trackEvent:AEPEdgeMediaEventStateEnd info:fullScreenState metadata:nil];
`}</code></pre>
    <p><strong parentName="p">{`Tracking ad breaks`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AdBreakStart
  NSDictionary *adBreakInfo = [AEPMobileEdgeMedia createAdBreakObjectWith:@"adbreakName" position:1 startTime:0];
  [tracker trackEvent:AEPEdgeMediaEventAdBreakStart info:adBreakInfo metadata:nil];

// AdBreakComplete
  [tracker trackEvent:AEPEdgeMediaEventAdBreakComplete info:nil metadata:nil];
`}</code></pre>
    <p><strong parentName="p">{`Tracking ads`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AdStart
  NSDictionary *adInfo = [AEPMobileEdgeMedia createAdObjectWith:@"adName" id:@"adId" position:0 length:30];
  NSMutableDictionary* adMetadata = [[NSMutableDictionary alloc] init];

// Standard metadata keys provided by adobe.
  [adMetadata setObject:@"Sample Advertiser" forKey:AEPEdgeAdMetadataKeys.ADVERTISER];
  [adMetadata setObject:@"Sample Campaign" forKey:AEPEdgeAdMetadataKeys.CAMPAIGN_ID];

// Custom metadata keys
  [adMetadata setObject:@"Sample Affiliate" forKey:@"affiliate"];

  [tracker trackEvent:AEPEdgeMediaEventAdStart info:adInfo metadata:adMetadata];

// AdComplete
  [tracker trackEvent:AEPEdgeMediaEventAdComplete info:nil metadata:nil];

// AdSkip
  [tracker trackEvent:AEPEdgeMediaEventAdSkip info:nil metadata:nil];
`}</code></pre>
    <p><strong parentName="p">{`Tracking chapters`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// ChapterStart
  NSDictionary *chapterInfo = [AEPMobileEdgeMedia createChapterObjectWith:@"chapterName" position:1 length:60 startTime:0];

  NSMutableDictionary *chapterMetadata = [[NSMutableDictionary alloc] init];
  [chapterMetadata setObject:@"Sample Segment type" forKey:@"segmentType"];

  [tracker trackEvent:AEPEdgeMediaEventChapterStart info:chapterInfo metadata:chapterMetadata];

// ChapterComplete
  [tracker trackEvent:AEPEdgeMediaEventChapterComplete info:nil metadata:nil];

// ChapterSkip
  [tracker trackEvent:AEPEdgeMediaEventChapterSkip info:nil metadata:nil];
`}</code></pre>
    <p><strong parentName="p">{`Tracking playback events`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// BufferStart
  [tracker trackEvent:AEPEdgeMediaEventBufferStart info:nil metadata:nil];

// BufferComplete
  [tracker trackEvent:AEPEdgeMediaEventBufferComplete info:nil metadata:nil];

// SeekStart
  [tracker trackEvent:AEPEdgeMediaEventSeekStart info:nil metadata:nil];

// SeekComplete
  [tracker trackEvent:AEPEdgeMediaEventSeekComplete info:nil metadata:nil];
`}</code></pre>
    <p><strong parentName="p">{`Tracking bitrate change`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// If the new bitrate value is available provide it to the tracker.
  NSDictionary *qoeInfo = [AEPMobileEdgeMedia createQoEObjectWith:50000 startTime:2 fps:24 droppedFrames:10];

// Bitrate change
  [tracker trackEvent:AEPEdgeMediaEventBitrateChange info:nil metadata:nil];
`}</code></pre>
    <Variant platform="android" api="update-current-playhead" repeat="12" mdxType="Variant" />
    <h4 {...{
      "id": "java-16"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void updateCurrentPlayhead(int time);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.updateCurrentPlayhead(1);
`}</code></pre>
    <p><strong parentName="p">{`Live streaming example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`//Calculation for number of seconds since midnight UTC of the day
int timeFromMidnightInSecond = (int)((System.currentTimeMillis() / 1000) % 86400);

tracker.updateCurrentPlayhead(timeFromMidnightInSecond);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-16"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.updateCurrentPlayhead(1)
`}</code></pre>
    <p><strong parentName="p">{`Live streaming example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val timeFromMidnightInSecond = ((System.currentTimeMillis() / 1000) % 86400).toInt()
tracker.updateCurrentPlayhead(timeFromMidnightInSecond)
}
`}</code></pre>
    <Variant platform="ios" api="update-current-playhead" repeat="12" mdxType="Variant" />
    <h4 {...{
      "id": "swift-16"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func updateCurrentPlayhead(time: Int)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`tracker.updateCurrentPlayhead(1)
`}</code></pre>
    <p><strong parentName="p">{`Live streaming example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`//Calculation for number of seconds since midnight UTC of the day
let secondsSince1970: TimeInterval = (Date().timeIntervalSince1970)
let timeFromMidnightInSecond = Int(secondsSince1970.truncatingRemainder(dividingBy: 86400))

tracker.updateCurrentPlayhead(time: timeFromMidnightInSecond)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-16"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) updateCurrentPlayhead:(NSInteger)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[tracker updateCurrentPlayhead:1];
`}</code></pre>
    <Variant platform="android" api="update-qoe-object" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-17"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public void updateQoEObject(Map<String, Object> qoeInfo);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> qoeInfo = Media.createQoEObject(1000000, 2, 25, 10);
tracker.updateQoEObject(qoeInfo);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-17"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val qoeInfo = Media.createQoEObject(1000000, 2, 25, 10)
tracker.updateQoEObject(qoeInfo)
`}</code></pre>
    <Variant platform="ios" api="update-qoe-object" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-17"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func updateQoEObject(qoe: [String: Any])
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let qoeInfo = Media.createQoEObjectWith(bitrate: 500000, startupTime: 2, fps: 24, droppedFrames: 10)
tracker.updateQoEObject(qoe: qoeInfo)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-17"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) updateQoEObject:(NSDictionary<NSString *,id> * _Nonnull)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *qoeInfo = [AEPMobileEdgeMedia createQoEObjectWith:50000 startTime:2 fps:24 droppedFrames:10]
[tracker updateQoEObject:qoeInfo];
`}</code></pre>
    <Variant platform="android" api="media-type" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class Media {

  public enum MediaType {
      /**
      * Constant defining media type for Video streams
      */
      Video,

      /**
      * Constant defining media type for Audio streams
      */
      Audio
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "java-18"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> mediaInfo = Media.createMediaObject("videoName",
                                                            "videoId",
                                                            60,
                                                            MediaConstants.StreamType.VOD,
                                                            Media.MediaType.Video)
`}</code></pre>
    <h4 {...{
      "id": "kotlin-18"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val mediaInfo = Media.createMediaObject("videoName",
                                        "videoId",
                                        60,
                                        MediaConstants.StreamType.VOD,
                                        Media.MediaType.Video)
`}</code></pre>
    <Variant platform="ios" api="media-type" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`@objc(AEPEdgeMediaType)
public enum MediaType: Int, RawRepresentable {
 //Constant defining media type for Video streams
 case Audio
 //Constant defining media type for Audio streams
 case Video
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "swift-18"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var mediaObject = Media.createMediaObjectWith(name: "videoName",
                                                id: "videoId",
                                            length: 60,
                                        streamType: MediaConstants.StreamType.VOD,
                                        mediaType: MediaType.Video)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-18"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`NSDictionary *mediaObject = [AEPMobileEdgeMedia createMediaObjectWith:@"videoName"
                                                               id:@"videoId"
                                                               length:60
                                                           streamType:AEPEdgeMediaStreamType.VOD
                                                            mediaType:AEPEdgeMediaTypeVideo];
`}</code></pre>
    <Variant platform="android" api="stream-type" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MediaConstants {

  public static final class StreamType {
      /**
      * Constant defining stream type for VOD streams
      */
      public static final String VOD = "vod";

      /**
      * Constant defining stream type for Live streams
      */
      public static final String LIVE = "live";

      /**
      * Constant defining stream type for Linear streams
      */
      public static final String LINEAR = "linear";

      /**
      * Constant defining stream type for Podcast streams
      */
      public static final String PODCAST = "podcast";

      /**
      * Constant defining stream type for Audiobook streams
      */
      public static final String AUDIOBOOK = "audiobook";

      /**
      * Constant defining stream type for AOD streams
      */
      public static final String AOD = "aod";
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "java-19"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> mediaInfo = Media.createMediaObject("videoName",
                                                            "videoId",
                                                            60,
                                                            MediaConstants.StreamType.VOD,
                                                            Media.MediaType.Video)
`}</code></pre>
    <h4 {...{
      "id": "kotlin-19"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val mediaInfo = Media.createMediaObject("videoName",
                                        "videoId",
                                        60,
                                        MediaConstants.StreamType.VOD,
                                        Media.MediaType.Video)
`}</code></pre>
    <Variant platform="ios" api="stream-type" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public class MediaConstants: NSObject {
  @objc(AEPEdgeMediaStreamType)
  public class StreamType: NSObject {
     // Constant defining stream type for VOD streams.
        public static let VOD = "vod"
     // Constant defining stream type for Live streams.
        public static let LIVE = "live"
     // Constant defining stream type for Linear streams.
        public static let LINEAR = "linear"
     // Constant defining stream type for Podcast streams.
        public static let PODCAST = "podcast"
     // Constant defining stream type for Audiobook streams.
        public static let AUDIOBOOK = "audiobook"
     // Constant defining stream type for AOD streams.
        public static let AOD = "aod"
    }
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "swift-19"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var mediaObject = Media.createMediaObjectWith(name: "videoName",
                                                id: "videoId",
                                            length: 60,
                                        streamType: MediaConstants.StreamType.VOD,
                                         mediaType: MediaType.Video)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-19"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`NSDictionary *mediaObject = [AEPMobileEdgeMedia createMediaObjectWith:@"videoName"
                                                               id:@"videoId"
                                                           length:60
                                                       streamType:AEPEdgeMediaStreamType.VOD
                                                        mediaType:AEPEdgeMediaTypeVideo];
`}</code></pre>
    <Variant platform="android" api="standard-video-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MediaConstants {

  public static final class VideoMetadataKeys {
    public static final String AD_LOAD = "adLoad";
    public static final String ASSET_ID = "assetID";
    public static final String AUTHORIZED = "isAuthenticated";
    public static final String DAY_PART = "dayPart";
    public static final String EPISODE = "episode";
    public static final String FEED = "feed";
    public static final String FIRST_AIR_DATE = "firstAirDate";
    public static final String FIRST_DIGITAL_DATE = "firstDigitalDate";
    public static final String GENRE = "genre";
    public static final String MVPD = "mvpd";
    public static final String NETWORK = "network";
    public static final String ORIGINATOR = "originator";
    public static final String SEASON = "season";
    public static final String SHOW = "show";
    public static final String SHOW_TYPE = "showType";
    public static final String STREAM_FORMAT = "streamFormat";
    public static final String RATING = "rating";
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "java-20"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> mediaInfo = Media.createMediaObject("videoName",
                                                            "videoId",
                                                            60,
                                                            MediaConstants.StreamType.VOD,
                                                            Media.MediaType.Video)

HashMap<String, String> videoMetadata = new HashMap<String, String>();

// Standard Video Metadata
videoMetadata.put(MediaConstants.VideoMetadataKeys.SHOW, "Sample Show");
videoMetadata.put(MediaConstants.VideoMetadataKeys.SEASON, "Sample Season");

tracker.trackSessionStart(mediaInfo, videoMetadata);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-20"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val mediaInfo = Media.createMediaObject("videoName",
                                        "videoId",
                                        60,
                                        MediaConstants.StreamType.VOD,
                                        Media.MediaType.Video)

val videoMetadata = mutableMapOf(MediaConstants.VideoMetadataKeys.SHOW to "Sample Show",
                                 MediaConstants.VideoMetadataKeys.SEASON to "Sample Season")

tracker.trackSessionStart(mediaInfo, videoMetadata)
`}</code></pre>
    <Variant platform="ios" api="standard-video-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public class MediaConstants: NSObject {
  @objc(AEPEdgeMediaVideoMetadataKeys)
  public class VideoMetadataKeys: NSObject {
        public static let AD_LOAD = "adLoad"
        public static let ASSET_ID = "assetID"
        public static let AUTHORIZED = "isAuthenticated"
        public static let DAY_PART = "dayPart"
        public static let EPISODE = "episode"
        public static let FEED = "feed"
        public static let FIRST_AIR_DATE = "firstAirDate"
        public static let FIRST_DIGITAL_DATE = "firstDigitalDate"
        public static let GENRE = "genre"
        public static let MVPD = "mvpd"
        public static let NETWORK = "network"
        public static let ORIGINATOR = "originator"
        public static let RATING = "rating"
        public static let SEASON = "season"
        public static let SHOW = "show"
        public static let SHOW_TYPE = "showType"
        public static let STREAM_FORMAT = "streamFormat"
    }
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "swift-20"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var mediaInfo = Media.createMediaObjectWith(name: "videoName", id: "videoId", length: 60, streamType: MediaConstants.StreamType.VOD, mediaType: MediaType.Video)

var videoMetadata: [String: String] = [:]
// Standard Video Metadata
videoMetadata[MediaConstants.VideoMetadataKeys.SHOW] = "Sample Show"
videoMetadata[MediaConstants.VideoMetadataKeys.SEASON] = "Sample Season"

tracker.trackSessionStart(info: mediaInfo, metadata: videoMetadata)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-20"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`NSDictionary *mediaInfo = [AEPMobileEdgeMedia createMediaObjectWith:@"videoName" id:@"videoId" length:60 streamType:AEPEdgeMediaStreamType.VOD mediaType:AEPEdgeMediaTypeVideo];

NSMutableDictionary *videoMetadata = [[NSMutableDictionary alloc] init];
// Standard Video Metadata
[videoMetadata setObject:@"Sample Show" forKey:AEPEdgeMediaVideoMetadataKeys.SHOW];
[videoMetadata setObject:@"Sample Season" forKey:AEPEdgeMediaVideoMetadataKeys.SEASON];

[tracker trackSessionStart:mediaInfo metadata:videoMetadata];
`}</code></pre>
    <Variant platform="android" api="standard-audio-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MediaConstants {

  public static final class AudioMetadataKeys {
    public static final String ALBUM = "album";
    public static final String ARTIST = "artist";
    public static final String AUTHOR = "author";
    public static final String LABEL = "label";
    public static final String PUBLISHER = "publisher";
    public static final String STATION = "station";
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "java-21"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> mediaInfo = Media.createMediaObject("videoName",
                                                            "videoId",
                                                            60,
                                                            MediaConstants.StreamType.AOD,
                                                            Media.MediaType.Audio)

HashMap<String, String> audioMetadata = new HashMap<String, String>();

// Standard Video Metadata
audioMetadata.put(MediaConstants.AudioMetadataKeys.ARTIST, "Sample Artist");
audioMetadata.put(MediaConstants.AudioMetadataKeys.ALBUM, "Sample Album");

tracker.trackSessionStart(mediaInfo, audioMetadata);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-21"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val mediaInfo = Media.createMediaObject("videoName",
                                        "videoId",
                                        60,
                                        MediaConstants.StreamType.AOD,
                                        Media.MediaType.Audio)

val audioMetadata = mutableMapOf(MediaConstants.AudioMetadataKeys.ARTIST to "Sample Artist",
                                 MediaConstants.AudioMetadataKeys.ALBUM to "Sample Album")

tracker.trackSessionStart(mediaInfo, audioMetadata)
`}</code></pre>
    <Variant platform="ios" api="standard-audio-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public class MediaConstants: NSObject {
  @objc(AEPEdgeMediaAudioMetadataKeys)
  public class AudioMetadataKeys: NSObject {
      public static let ALBUM = "album"
      public static let ARTIST = "artist"
      public static let AUTHOR = "author"
      public static let LABEL = "label"
      public static let PUBLISHER = "publisher"
      public static let STATION = "station"
    }
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "swift-21"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var audioObject = Media.createMediaObjectWith(name: "audioName", id: "audioId", length: 30, streamType: MediaConstants.StreamType.AOD, mediaType: MediaType.AUDIO)

var audioMetadata: [String: String] = [:]
// Standard Audio Metadata
audioMetadata[MediaConstants.AudioMetadataKeys.ARTIST] = "Sample Artist"
audioMetadata[MediaConstants.AudioMetadataKeys.ALBUM] = "Sample Album"

tracker.trackSessionStart(info: audioObject, metadata: audioMetadata)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-21"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`NSDictionary *audioObject = [AEPMobileEdgeMedia createMediaObjectWith:@"audioName" id:@"audioid" length:30 streamType:AEPEdgeMediaStreamType.AOD mediaType:AEPEdgeMediaTypeAudio];

NSMutableDictionary *audioMetadata = [[NSMutableDictionary alloc] init];
// Standard Audio Metadata
[audioMetadata setObject:@"Sample Artist" forKey:AEPEdgeMediaAudioMetadataKeys.ARTIST];
[audioMetadata setObject:@"Sample Album" forKey:AEPEdgeMediaAudioMetadataKeys.ALBUM];

[tracker trackSessionStart:audioObject metadata:audioMetadata];
`}</code></pre>
    <Variant platform="android" api="standard-ad-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MediaConstants {

  public static final class AdMetadataKeys {
    public static final String ADVERTISER = "advertiser";
    public static final String CAMPAIGN_ID = "campaignID";
    public static final String CREATIVE_ID = "creativeID";
    public static final String CREATIVE_URL = "creativeURL";
    public static final String PLACEMENT_ID = "placementID";
    public static final String SITE_ID = "siteID";
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "java-22"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> adInfo = Media.createAdObject("adName", "adId", 1, 15);

HashMap<String, String> adMetadata = new HashMap<String, String>();

// Standard Video Metadata
adMetadata.put(MediaConstants.AdMetadataKeys.ADVERTISER, "Sample Advertiser");
adMetadata.put(MediaConstants.AdMetadataKeys.CAMPAIGN_ID, "Sample Campaign");

tracker.trackEvent(Media.Event.AdStart, adInfo, adMetadata);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-22"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val adInfo = Media.createAdObject("adName", "adId", 1, 15)
val adMetadata = mutableMapOf(MediaConstants.AdMetadataKeys.ADVERTISER to "Sample Advertiser",
                              MediaConstants.AdMetadataKeys.CAMPAIGN_ID to "Sample Campaign")


tracker.trackEvent(Media.Event.AdStart, adInfo, adMetadata)
`}</code></pre>
    <Variant platform="ios" api="standard-ad-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public class MediaConstants: NSObject {
  @objc(AEPEdgeAdMetadataKeys)
  public class AdMetadataKeys: NSObject {
      public static let ADVERTISER = "advertiser"
      public static let CAMPAIGN_ID = "campaignID"
      public static let CREATIVE_ID = "creativeID"
      public static let CREATIVE_URL = "creativeURL"
      public static let PLACEMENT_ID = "placementID"
      public static let SITE_ID = "siteID"
    }
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "swift-22"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let adInfo = Media.createObjectWith(name: "adName", id: "adId", position: 0, length: 30)
var adMetadata: [String: String] = [:]
// Standard Ad Metadata
adMetadata[MediaConstants.AdMetadataKeys.ADVERTISER] = "Sample Advertiser"
adMetadata[MediaConstants.AdMetadataKeys.CAMPAIGN_ID] = "Sample Campaign"

tracker.trackEvent(event: MediaEvent.AdStart, info: adInfo, metadata: adMetadata)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-22"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`NSDictionary *adInfo = [AEPMobileEdgeMedia createAdObjectWith:@"adName" id:@"adId" position:0 length:30];

NSMutableDictionary *adMetadata = [[NSMutableDictionary alloc] init];
// Standard Ad Metadata
[adMetadata setObject:@"Sample Advertiser" forKey:AEPEdgeAdMetadataKeys.ADVERTISER];
[adMetadata setObject:@"Sample Campaign" forKey:AEPEdgeAdMetadataKeys.CAMPAIGN_ID];

[tracker trackEvent:AEPEdgeMediaEventAdStart info:adInfo metadata:adMetadata];
`}</code></pre>
    <Variant platform="android" api="player-state-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MediaConstants {

  public static final class PlayerState {
    public static final String FULLSCREEN = "fullscreen";
    public static final String PICTURE_IN_PICTURE = "pictureInPicture";
    public static final String CLOSED_CAPTION = "closeCaption";
    public static final String IN_FOCUS = "inFocus";
    public static final String MUTE = "mute";
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "java-23"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> fullScreenState = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-23"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val fullScreenState = Media.createStateObject(MediaConstants.PlayerState.FULLSCREEN)
`}</code></pre>
    <Variant platform="ios" api="player-state-constants" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public class MediaConstants: NSObject {
  @objc(AEPEdgeMediaPlayerState)
  public class PlayerState: NSObject {
        public static let FULLSCREEN = "fullscreen"
        public static let PICTURE_IN_PICTURE = "pictureInPicture"
        public static let CLOSED_CAPTION = "closeCaption"
        public static let IN_FOCUS = "inFocus"
        public static let MUTE = "mute"
    }
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "swift-23"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let inFocusState = Media.createStateObjectWith(stateName: MediaConstants.PlayerState.IN_FOCUS)
tracker.trackEvent(event: MediaEvent.StateStart, info: inFocusState, metadata: nil)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-23"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`NSDictionary* inFocusState = [AEPMobileEdgeMedia createStateObjectWith:AEPEdgeMediaPlayerState.IN_FOCUS];
[tracker trackEvent:AEPEdgeMediaEventStateStart info:muteState metadata:nil];
`}</code></pre>
    <Variant platform="android" api="media-events" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class Media {

    /**
    * These enumeration values define the type of a tracking event
    */
    public enum Event {
      /**
      * Constant defining event type for AdBreak start
      */
      AdBreakStart,

      /**
      * Constant defining event type for AdBreak complete
      */
      AdBreakComplete,

      /**
      * Constant defining event type for Ad start
      */
      AdStart,

      /**
      * Constant defining event type for Ad complete
      */
      AdComplete,

      /**
      * Constant defining event type for Ad skip
      */
      AdSkip,

      /**
      * Constant defining event type for Chapter start
      */
      ChapterStart,

      /**
      * Constant defining event type for Chapter complete
      */
      ChapterComplete,

      /**
      * Constant defining event type for Chapter skip
      */
      ChapterSkip,

      /**
      * Constant defining event type for Seek start
      */
      SeekStart,

      /**
      * Constant defining event type for Seek complete
      */
      SeekComplete,

      /**
      * Constant defining event type for Buffer start
      */
      BufferStart,

      /**
      * Constant defining event type for Buffer complete
      */
      BufferComplete,

      /**
      * Constant defining event type for change in Bitrate
      */
      BitrateChange,

      /**
      * Constant defining event type for State start
      */
      StateStart,

      /**
      * Constant defining event type for State end
      */
      StateEnd
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Examples`}</strong></p>
    <h4 {...{
      "id": "java-24"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackEvent(Media.Event.BitrateChange, null, null);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-24"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`tracker.trackEvent(Media.Event.BitrateChange, null, null)
`}</code></pre>
    <Variant platform="ios" api="media-events" repeat="6" mdxType="Variant" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`@objc(AEPEdgeMediaEvent)
public enum MediaEvent: Int, RawRepresentable {
 // event type for AdBreak start
    case AdBreakStart
 // event type for AdBreak Complete
    case AdBreakComplete
 // event type for Ad Start
    case AdStart
 // event type for Ad Complete
    case AdComplete
 // event type for Ad Skip
    case AdSkip
 // event type for Chapter Start
    case ChapterStart
 // event type for Chapter Complete
    case ChapterComplete
 // event type for Chapter Skip
    case ChapterSkip
 // event type for Seek Start
    case SeekStart
 // event type for Seek Complete
    case SeekComplete
 // event type for Buffer Start
    case BufferStart
 // event type for Buffer Complete
    case BufferComplete
 // event type for change in Bitrate
    case BitrateChange
 // event type for Player State Start
    case StateStart
 // event type for Player State End
    case StateEnd
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "swift-24"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`tracker.trackEvent(event: MediaEvent.BitrateChange, info: nil, metadata: nil)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-24"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`[tracker trackEvent:AEPEdgeMediaEventBitrateChange info:nil metadata:nil];
`}</code></pre>
    <Variant platform="android" api="media-resume" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-25"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MediaConstants {

  public static final class MediaObjectKey {
      /**
      * Constant defining explicit media resumed property. Set this to true on MediaObject if resuming a previously closed session.
      */
      public static final String RESUMED;
  }

}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "java-26"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> mediaInfo = Media.createMediaObject("mediaName", "mediaId", 60, MediaConstants.StreamType.VOD, Media.MediaType.Video);

// Attach media resumed information.
mediaInfo.put(MediaConstants.MediaObjectKey.RESUMED, true);

tracker.trackSessionStart(mediaInfo, null);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-25"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val mediaInfo = Media.createMediaObject("mediaName", "mediaId", 60, MediaConstants.StreamType.VOD, Media.MediaType.Video)

// Attach media resumed information.
mediaInfo[MediaConstants.MediaObjectKey.RESUMED] = true

tracker.trackSessionStart(mediaInfo, null)
`}</code></pre>
    <Variant platform="ios" api="media-resume" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-25"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public class MediaConstants: NSObject {
 @objc(AEPEdgeMediaObjectKey)
 public class MediaObjectKey: NSObject {
        public static let RESUMED = "media.resumed"
    }
}
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var mediaObject = Media.createMediaObjectWith(name: "videoName", id: "videoId", length: 60, streamType: MediaConstants.StreamType.VOD, mediaType: MediaType.Video)
mediaObject[MediaConstants.MediaObjectKey.RESUMED] = true

tracker.trackSessionStart(info: mediaObject, metadata: nil)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-25"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`@interface AEPEdgeMediaObjectKey : NSObject
+ (NSString * _Nonnull)RESUMED
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *mediaObject = [AEPMobileEdgeMedia createMediaObjectWith:@"videoName" id:@"videoId" length:60 streamType:AEPEdgeMediaStreamType.VOD mediaType:AEPEdgeMediaTypeVideo];

// Attach media resumed information.
NSMutableDictionary *obj  = [mediaObject mutableCopy];
[obj setObject:@YES forKey:AEPEdgeMediaObjectKey.RESUMED];

[tracker trackSessionStart:obj metadata:nil];
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      